// i18next-extract-mark-ns-start about-us-contact

import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const AboutPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection theme="greytint" layout="fullwidth">
				<div className="col xl6 offset-xl3 l8 offset-l2 m10 offset-m1 s12 offset-s0">
					<ContactForm name="contact" title={t("_GetInTouch")} />
				</div>
			</PageSection>
		</Page>
	);
};

export default AboutPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-contact", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/contact-us/Realis-Simulation_ContactUs.jpg" }) {
		...imageBreaker
	}
}
`;

